import React, { useState, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import Hero from './Hero.js';
import ClusterDiagram from './ClusterDiagram.js';
import Dunkin from './Dunkin.js';
import Admin from './admin.js';
import Tacobell from './Tacobell.js';
import Jimmyjohns from './Jimmyjohns.js';
import Footer from './Footer.js';
//import Navbar from './Navbar.js';

const Home = () => {
  const [scrollTo, setScrollTo] = useState(null);

  const handleScrollTo = (id) => {
    setScrollTo(id);
  };

  useEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setScrollTo(null);
      }
    }
  }, [scrollTo]);

  return (
    <div>
    <Container className="mt-5">
      <Row>
      <Hero handleScrollTo={handleScrollTo}/>
      </Row>



    </Container>

    <Container className="mt-5">


      <Row id="admin-section">
        <Admin />
      </Row>
      <Row id="dunkin-section">
        <Dunkin />
      </Row>
      <Row id="taco-bell-section">
        <Tacobell />
      </Row>
      <Row id="jimmy-johns-section">
        <Jimmyjohns />
      </Row>


      <Row>
        <Footer />
      </Row>
    </Container>
    </div>
  );
};

export default Home;
