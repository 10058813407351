import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-gray-800 pt-10 sm:mt-10 pt-10 pb-5">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:flex-wrap md:items-center justify-between">
          <div className="flex justify-center md:order-2">
            <a href="#" className="text-gray-400 hover:text-white">
              <span className="sr-only">Facebook</span>
              <FaFacebook className="h-6 w-6" />
            </a>
            <a href="#" className="ml-4 text-gray-400 hover:text-white">
              <span className="sr-only">Twitter</span>
              <FaTwitter className="h-6 w-6" />
            </a>
            <a href="#" className="ml-4 text-gray-400 hover:text-white">
              <span className="sr-only">Instagram</span>
              <FaInstagram className="h-6 w-6" />
            </a>
          </div>
          <div className="text-center md:mt-0 md:order-1">
          <p className="text-gray-400">&copy; 2023 Diya Holdings LLC. All rights reserved. Made with ❤️ by Vic Patel.</p>
          </div>
          <nav className="flex justify-center space-x-4 md:order-3 mt-4 md:mt-0">
            <a href="#" className="text-gray-400 hover:text-white">
              Contact
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              Privacy
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              Terms
            </a>
          </nav>
        </div>
      </div>
    </footer>
  );
}
