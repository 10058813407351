import { Link } from 'react-router-dom'

import "./App.css";


const people = [
  {
    name: 'NDCP',
    role: 'Dunkin Supply Chain',
    imageUrl: process.env.PUBLIC_URL + '/images/ndcp.jpeg',
    link: 'https://nationaldcp.com/',
  },
  {
    name: 'Jera',
    role: 'Donut Supply Chain',
    imageUrl: process.env.PUBLIC_URL + '/images/jera.jpeg',
    link: 'https://app.supplyit.com/Account/Home/Index',
  },
  {
    name: 'Dairy Farmers',
    role: 'Ice Cream Supply Chain',
    imageUrl: process.env.PUBLIC_URL + '/images/dfa.jpeg',
    link: 'https://brorders.dfamilk.com/Home/',
  },
  {
    name: 'Franchise Central',
    role: 'Franchise Portal',
    imageUrl: process.env.PUBLIC_URL + '/images/db.png',
    link: 'https://franchisee.dunkinbrands.com/DunkinPortal',
  },
  {
    name: 'Medallia',
    role: 'GSS',
    imageUrl: process.env.PUBLIC_URL + '/images/medallia.svg',
    link: 'https://medallia-prod-idp.dunkinbrands.com/',
  },
  {
    name: 'CrunchTime',
    role: "Dunkin' Reports",
    imageUrl: process.env.PUBLIC_URL + '/images/crunchtime.png',
    link: 'https://dbi2071-em.net-chef.com/standalone/modern.ct#Login',
  },
];

export default function Dunkin() {



  return (

    <div  className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-y-20 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
           <img src={process.env.PUBLIC_URL + '/images/dunkin.png'} alt="Dunkin Logo" className="h-8 w-auto" />
          <p className="mt-6 text-lg leading-8 text-gray-600"></p>
        </div>
        <ul
          role="list"
          className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2"
        >
          {people.map((person) => (
            <li key={person.name}>
              <Link
                to={person.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-6 text-decoration-none person-card"
              >
                <img
                  className="h-16 w-16 rounded-full"
                  src={person.imageUrl}
                  alt=""
                />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">
                    {person.role}
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
