import { Link } from 'react-router-dom'

import "./App.css";


const people = [
  {
    name: 'McLane',
    role: 'Taco Bell Supply Chain',
    imageUrl: process.env.PUBLIC_URL + '/images/mcl.png',
    link: 'https://www.mclaneco.com/content/mclaneco/en/home/login-partner.html',
  },
  {
    name: 'My Yum Brands',
    role: 'Franchise Portal',
    imageUrl: process.env.PUBLIC_URL + '/images/mytb.png',
    link: 'https://mytacobell.yum.com/',
  },
  {
    name: 'SMG 360',
    role: 'GSS',
    imageUrl: process.env.PUBLIC_URL + '/images/smg.svg',
    link: 'https://reporting.smg.com/index.aspx',
  },
  {
    name: 'Delaget',
    role: 'Tacobell Trend',
    imageUrl: process.env.PUBLIC_URL + '/images/del.png',
    link: 'https://dashboard.b50first.com/dashboard',
  },
  {
    name: 'E-Restaurants',
    role: 'Taco Bell Reports',
    imageUrl: process.env.PUBLIC_URL + '/images/alta.jpeg',
    link: 'https://yumtb.altametrics.com/aim/login/AuthSuccessController.do',
  },

];

export default function Tacobell() {



  return (

    <div  className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-y-20 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
           <img src={process.env.PUBLIC_URL + '/images/taco.png'} alt="Dunkin Logo" style={{"width": "200px", "height": "120px"}}className="h-8 w-auto" />
          <p className="mt-6 text-lg leading-8 text-gray-600"></p>
        </div>
        <ul
          role="list"
          className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2"
        >
          {people.map((person) => (
            <li key={person.name}>
              <Link
                to={person.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-6 text-decoration-none person-card"
              >
                <img
                  className="h-16 w-16 rounded-full"
                  src={person.imageUrl}
                  alt=""
                />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">
                    {person.role}
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
