import { Link } from 'react-router-dom'

import "./App.css";


const people = [
  {
    name: 'Sysco',
    role: 'Jimmy Johns Supply Chain',
    imageUrl: process.env.PUBLIC_URL + '/images/sysco.png',
    link: 'https://shop.sysco.com/auth/login',
  },
  {
    name: '',
    role: 'Grocery Supply Chain',
    imageUrl: process.env.PUBLIC_URL + '/images/jera.jpeg',
    link: 'https://app.supplyit.com/Account/Home/Index',
  },
  {
    name: 'Micromatics',
    role: 'Jimmy Johns Reports',
    imageUrl: process.env.PUBLIC_URL + '/images/macro.jpeg',
    link: 'https://jimmyjohns.macromatix.net/MMS_Logon.aspx?SessionTimeout=True&v=pt&ReturnUrl=/MMS_Ops_DashSales.aspx',
  },
  {
    name: 'Fast Track',
    role: 'Franchise Portal Fast Track',
    imageUrl: process.env.PUBLIC_URL + '/images/jjft.png',
    link: 'https://inspirebrands.csod.com/client/inspirebrands/jimmyjohns.aspx',
  },
  {
    name: 'GSS?',
    role: 'GSS',
    imageUrl: process.env.PUBLIC_URL + '/images/medallia.svg',
    link: 'https://medallia-prod-idp.dunkinbrands.com/',
  },

];

export default function Jimmyjohns() {



  return (

    <div  className="bg-white py-24 sm:py-32">
      <div className="mx-auto grid max-w-7xl gap-y-20 gap-x-8 px-6 lg:px-8 xl:grid-cols-3">
        <div className="max-w-2xl">
           <img src={process.env.PUBLIC_URL + '/images/jj.jpeg'} style={{"width": "220px", "height": "100px"}} alt="Dunkin Logo" className="h-8 w-auto" />
          <p className="mt-6 text-lg leading-8 text-gray-600"></p>
        </div>
        <ul
          role="list"
          className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2"
        >
          {people.map((person) => (
            <li key={person.name}>
              <Link
                to={person.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-6 text-decoration-none person-card"
              >
                <img
                  className="h-16 w-16 rounded-full"
                  src={person.imageUrl}
                  alt=""
                />
                <div>
                  <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-sm font-semibold leading-6 text-indigo-600">
                    {person.role}
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
