// import React from "react";
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import "./App.css";
import Login from "./Login.js"
import Home from "./Home.js"
// import NavBar from "./NavBar.js"
import NotFound from "./Notfound.js"


function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   const loadScript = (src) => {
  //     const script = document.createElement('script');
  //     script.src = src;
  //     script.type = 'text/javascript';
  //     script.charset = 'utf-8';
  //     document.body.appendChild(script);
  //   };
  //
  //   loadScript(
  //     'https://cdn.venngage.com/23.09.0/3821d0b79d7a886bccfd7b858451cb92a6e7754c/vendor.bundle.js?v=23.09.0',
  //   );
  //   loadScript(
  //     'https://cdn.venngage.com/23.09.0/3821d0b79d7a886bccfd7b858451cb92a6e7754c/widgets/js/widgets.bundle.js',
  //   );
  // }, []);

  return (
    <BrowserRouter>

      <Routes>

            <Route path="/" element={<Home />} />


        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
